<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h1 class="highlighted-color1">
          <span>{{ $t('reports') }}</span>
        </h1>
      </div>
    </div>
    <!-- REPORTS -->
    <div class="mt-4">
      <div v-if="!user && loader" class="text-center">
        <Loader
          :size="'big'"
        />
      </div>
      <div v-else>
        <template v-if="user">
          <!-- TABS -->
          <b-tabs content-class="mt-4" nav-class="default-tabs" v-model="tabIndex">
            <!-- REPORTS TAB -->
            <b-tab :title="$t('reports')">
              <!-- REPORTS -->
              <div>
                <div class="col-lg-8 mx-auto">
                  <div class="card mb-3">
                    <div class="card-body d-flex justify-content-between align-items-center default-form">
                      <div>
                        <h6 class="fw-bold mb-2">{{ $t('linking_reports_with_spreadsheets') }}</h6>
                        <p class="font-size-xSmall lh-sm mb-0" v-html="$t('info_spreadsheets')"></p>
                      </div>
                      <div>
                        <div class="form-check form-switch" v-if="!spreadsheetId && !linkSpreadsheetLoader">
                          <input
                            class="form-check-input size-lg"
                            type="checkbox" role="switch"
                            id="switch-spreadshet"
                            v-model="linkSpreadsheet"
                            @change ="linkWithSpreadsheet"
                            v-b-tooltip.hover :title="$t('enable_linking_to_spreadsheets')"
                          >
                          <label class="form-check-label" for="switch-spreadshet"></label>
                        </div>
                      </div>
                      <div v-if="linkSpreadsheetLoader">
                        <Loader
                          :size="'small'"
                          :align="'start'"
                        />
                      </div>
                      <div v-if="spreadsheetId">
                        <a class="btn btn-color1 btn-sm" target="_blank" :href="`https://docs.google.com/spreadsheets/d/${spreadsheetId}`">{{ $t('access_spreadsheet') }}</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-8 mx-auto">
                  <div class="card">
                    <div class="card-body">
                      <form @submit.prevent="submit" class="default-form">
                        <p>{{ $t('you_will_receive_the_report_by_email') }}</p>
                        <!-- ADVERTISER -->
                        <div class="form-group mb-3">
                          <label class="form-label fw-bold">
                            {{ $t('advertiser') }}
                          </label>
                          <div v-if="advertisersLoader">
                            <Loader
                              :size="'small'"
                              :align="'start'"
                            />
                          </div>
                          <div v-else>
                            <Multiselect
                              v-model="form.advertisers"
                              :placeholder="$t('select')"
                              label="label"
                              track-by="id"
                              :options="advertiserOptions"
                              :multiple="true"
                            />
                          </div>
                        </div> <!-- END ADVERTISER -->
                        <!-- CAMPAIGNS -->
                        <div class="form-group mb-3">
                          <label class="form-label fw-bold">
                            {{ $t('campaign') }}
                          </label>
                          <div v-if="campaignsLoader">
                            <Loader
                              :size="'small'"
                              :align="'start'"
                            />
                          </div>
                          <div v-else>
                            <Multiselect
                              v-model="form.campaigns"
                              :placeholder="$t('select')"
                              label="label"
                              track-by="id"
                              :options="campaignOptions"
                              :multiple="true"
                            />
                          </div>
                        </div> <!-- END CAMPAIGNS -->
                        <!-- DATES -->
                        <div class="form-group mb-3">
                          <label class="form-label fw-bold">{{ $t('period') }}</label>
                          <div class="d-flex">
                            <!-- START DATE -->
                            <div class="col-lg-3">
                              <DateTimeInput
                                v-model.trim="form.start_date"
                                format="dd/MM/yyyy"
                                type="date"
                                theme="basic"
                                class="mx-2"
                                :placeholder="$t('start')"
                              />
                            </div> <!-- END START DATE -->
                            <!-- END DATE -->
                            <div class="col-lg-3">
                              <DateTimeInput
                                v-model.trim="form.end_date"
                                format="dd/MM/yyyy"
                                type="date"
                                theme="basic"
                                class="mx-2"
                                :placeholder="$t('end')"
                              />
                            </div> <!-- END END DATE -->
                          </div>
                        </div> <!-- END DATES -->
                        <!-- TYPE -->
                        <div class="form-group mb-3">
                          <label class="form-label fw-bold">{{ $t('report_type') }}</label>
                          <div class="d-flex">
                            <div class="styled-checkbox me-3">
                              <label for="campaign">
                                <input v-model="form.type" type="radio" id="campaign" value="CAMPAIGN" />
                                <span></span>
                                {{ $t('by_campaign') }}
                              </label>
                            </div>
                            <div class="styled-checkbox me-3">
                              <label for="creative">
                                <input v-model="form.type" type="radio" id="creative" value="CREATIVE" />
                                <span></span>
                                {{ $t('by_creative') }}
                              </label>
                            </div>
                            <div class="styled-checkbox me-3">
                              <label for="location">
                                <input v-model="form.type" type="radio" id="location" value="LOCATION" />
                                <span></span>
                                {{ $t('by_location') }}
                              </label>
                            </div>
                            <div class="styled-checkbox me-3">
                              <label for="app_url">
                                <input v-model="form.type" type="radio" id="app_url" value="APP_URL" />
                                <span></span>
                                {{ $t('by_urls') }}
                              </label>
                            </div>
                            <div class="styled-checkbox">
                              <label for="conversion">
                                <input v-model="form.type" type="radio" id="conversion" value="CONVERSION" />
                                <span></span>
                                {{ $t('by_conversion') }}
                              </label>
                            </div>
                          </div>
                        </div> <!-- END TYPE -->
                        <div
                          class="mb-2 alert-message-card text-color2 font-size-small px-3 py-2"
                          v-if="form.type == 'APP_URL' || form.type == 'LOCATION' || form.type == 'CONVERSION'"
                        >
                          <p
                            class="mb-0"
                          >
                          {{ $t('reports_30_minutes_to_generate') }}
                          </p>
                        </div> <!-- END MESSAGES -->
                        <!-- TYPE -->
                        <div class="form-group mb-3">
                          <label class="form-label fw-bold">{{ $t('view_type') }}</label>
                          <div class="d-flex">
                            <div class="styled-checkbox me-3">
                              <label for="cumulative">
                                <input v-model="form.grouping" type="radio" id="cumulative" value="CUMULATIVE" />
                                <span></span>
                                {{ $t('cumulative') }}
                              </label>
                            </div>
                            <div class="styled-checkbox">
                              <label for="by_day">
                                <input v-model="form.grouping" type="radio" id="by_day" value="BY_DAY" />
                                <span></span>
                                {{ $t('by_day') }}
                              </label>
                            </div>
                          </div>
                        </div> <!-- END TYPE -->
                        <!-- EMAIL -->
                        <div class="form-group mb-3">
                          <label class="form-label fw-bold">{{ $t('report_destination_email') }}</label>
                          <input type="email" class="form-control" :placeholder="$t('example_email')" v-model.trim="form.email_to_send" />
                        </div> <!-- END EMAIL -->

                        <div class="mt-4 text-center">
                          <Button
                            class="btn btn-primary"
                            type="submit"
                            :disabled="$v.$invalid"
                            :loading="loader"
                          >
                            {{ $t('generate_report') }}
                          </Button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab><!-- END REPORTS TAB -->
            <!-- DASHBOARD TAB -->
            <b-tab :title="$t('dashboard')" v-if="dashboards.length > 0">
              <!-- DASHBOARD -->
              <div class="default-form">
                <!-- DASHBOARDS -->
                <div class="form-group mb-3">
                  <div v-if="dashboardsLoader">
                    <Loader
                      :size="'small'"
                      :align="'start'"
                    />
                  </div>
                  <div v-else>
                    <div class="col-lg-6 mx-lg-auto">
                      <h4 class="mb-4 text-center fw-600">{{ $t('select_a_dashboard') }}</h4>
                      <p class="mb-2 text-center">{{ $t('select_a_dashboard_to_view') }}</p>
                      <div class="custom-select">
                        <select
                          class="form-control"
                          v-model="dashboardSelected"
                        >
                          <option disabled="true" selected="true">{{ $t('select_the_dashboard') }}</option>
                          <option v-for="(dashboard, index) in dashboardOptions" :key="index" :value="dashboard.id">{{ dashboard.label }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div> <!-- END DASHBOARDS -->
                <div v-if="embedUrl">
                  <iframe width="100%" height="800" :src="embedUrl" frameborder="0" style="border:0" allowfullscreen sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"></iframe>
                </div>
              </div>
            </b-tab><!-- END DASHBOARD TAB -->
          </b-tabs>
        </template>
      </div>
    </div> <!-- END REPORTS -->
  </div>
</template>

<script>

import AdvertisersService from '@/modules/advertisers/services/advertisers-service'
import CampaignsService from '@/modules/campaigns/services/campaigns-service'
import CompaniesService from '@/modules/companies/services/companies-service'
import DashboardsService from '@/modules/dashboards/services/dashboards-service'
import NotificationService from '@/modules/notifications/services/notifications-service'
import ReportsService from '@/modules/reports/services/reports-service'
import { mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'

import Button from '@/components/common/Button/Button'
import DateTimeInput from '@/components/common/Input/DateTimeInput'
import Loader from '@/components/common/Loader/Loader'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Button,
    DateTimeInput,
    Loader,
    Multiselect
  },
  data () {
    return {
      advertisers: [],
      advertisersLoader: false,
      campaigns: [],
      campaignsLoader: false,
      dashboards: [],
      dashboardsLoader: false,
      dashboardSelected: null,
      embedUrl: null,
      form: {
        start_date: null,
        end_date: null,
        advertisers: [],
        campaigns: [],
        grouping: null,
        type: null,
        email_to_send: ''
      },
      linkSpreadsheet: false,
      spreadsheetId: '',
      linkSpreadsheetLoader: false,
      loader: false,
      tabIndex: 0
    }
  },
  metaInfo () {
    return {
      title: this.$t('reports')
    }
  },
  validations () {
    return {
      form: {
        start_date: { required },
        end_date: { required },
        advertisers: { required },
        type: { required },
        grouping: { required },
        email_to_send: { required }
      }
    }
  },
  created () {
    if (this.user) {
      this.getDashboards()
      this.getAdvertisers()
      this.form.email_to_send = this.user.email
      this.spreadsheetId = this.user.company.spreadsheet_id
    }
  },
  watch: {
    user (newUser) {
      this.getDashboards()
      this.getAdvertisers()
      this.form.email_to_send = this.user.email
      this.spreadsheetId = this.user.company.spreadsheet_id
    },
    'form.advertisers': function () {
      return this.advertisers.map(item => {
        return this.getCampaigns(item.id)
      })
    },
    dashboardSelected (newVal) {
      this.embedUrl = null
      if (newVal) {
        const dash = this.dashboardOptions.find(item => {
          return item.id === newVal
        })
        this.embedUrl = dash.embed_url
      }
    }
  },
  computed: {
    ...mapState('users', {
      user: state => state.user
    }),
    advertiserOptions () {
      return this.advertisers.map(item => {
        return {
          label: item.name,
          id: item.id
        }
      })
    },
    campaignOptions () {
      const allowedAdvertiserIds = this.form.advertisers.map(advertiser => advertiser.id)

      return this.campaigns
        .filter(item => allowedAdvertiserIds.includes(item.advertiser.id))
        .map(item => ({
          label: item.advertiser.name + ' - ' + item.name,
          id: item.id
        }))
    },
    dashboardOptions () {
      return this.dashboards.map(item => {
        return {
          label: item.name,
          id: item.id,
          embed_url: item.embed_url
        }
      })
    }
  },
  methods: {
    /**
     * Get advertisers
     */
    async getAdvertisers () {
      this.advertisersLoader = true
      try {
        const advertisers = await AdvertisersService.getAdvertisers({
          companyId: this.user.company.id,
          itemsPerPage: 100
        })
        this.advertisers = advertisers.data
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.advertisersLoader = false
    },
    /**
     * Get campaigns
     */
    async getCampaigns () {
      this.campaignsLoader = true
      try {
        const campaigns = await CampaignsService.getCampaigns({
          companyId: this.user.company.id,
          page: 0
        })
        this.campaigns = campaigns.data
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.campaignsLoader = false
    },
    /**
     * Get dashboards
     */
    async getDashboards () {
      this.dashboardsLoader = true
      try {
        const dashboards = await DashboardsService.getDashboards({
          companyId: this.user.company.id,
          page: 1
        })
        this.dashboards = [...this.dashboards, ...dashboards.data]
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.dashboardsLoader = false
    },
    /**
     * Submit form
     */
    async submit () {
      this.loader = true
      try {
        const form = Object.assign({}, this.form)
        form.advertisers = this.form.advertisers.map(item => {
          return item.id
        })
        form.campaigns = this.form.campaigns.map(item => {
          return item.id
        })
        await ReportsService.requestReport(form)
        this.form.start_date = null
        this.form.end_date = null
        this.form.advertisers = []
        this.form.campaigns = []
        this.form.type = null
        this.form.grouping = null
        const notification = {
          notification: {
            type: 'success',
            alertComponent: true,
            content: this.$t('we_are_preparing_your_report') + '<br/><strong>' + this.form.email_to_send + '</strong>'
          }
        }
        NotificationService.createInfoNotification(notification)
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    },
    /**
     * link with spreadsheet
     */
    async linkWithSpreadsheet () {
      this.linkSpreadsheetLoader = true
      try {
        await CompaniesService.linkWithSpreadsheet(this.user.company.id)

        const notification = {
          notification: {
            type: 'success',
            content: this.$t('successfully_enabled')
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
        location.reload()
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
    },
    /*
     * Set tab index
     */
    setTabIndex () {
      const tab = this.$route.query.tab
      let index = 0
      switch (tab) {
        case 'dashboard':
          index = 0
          break
        case 'reports':
          index = 1
          break
        default:
          break
      }
      this.tabIndex = index
    }
  }
}
</script>

<style lang="css" src="vue-multiselect/dist/vue-multiselect.min.css"></style>
